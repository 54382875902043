// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/bg.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --mailinweb-color-1: rgb(8, 114, 195);
    --el-color-primary: var(--mailinweb-color-1);
    --el-mask-color: rgba(0, 0, 0, 0.1);
}

body {
    margin: 0;
    font-family: "Roboto";
    overflow: hidden;
}

:not(input):not(textarea) {
    -webkit-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.el-button.is-round {
    padding: 8px 10%;
    align-self: center;
}

.el-row {
    opacity: 0;
    transition: all 0.5s;
    transition-delay: 0.5s;
}

.el-row.show {
    opacity: 1;
}

.el-form {
    display: flex;
    flex-direction: column;
    gap: 22px;
    width: 100%;
}

.el-col:nth-of-type(1) .el-scrollbar__view,
.el-col:nth-of-type(2) .el-scrollbar__view {
    display: grid;
    align-items: center;
    height: calc(var(--vh, 1vh) * 100);
}

.el-col:nth-of-type(1) .el-scrollbar__view > div,
.el-col:nth-of-type(2) .el-scrollbar__view > div {
    display: flex;
    flex-direction: column;
    padding: 10%;
    align-items: center;
    gap: 40px;
}

.el-col:nth-of-type(1) {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-position: right bottom;
    background-size: cover;
}

/* IMAGEN MUNDO */
.el-col:nth-of-type(1) .el-image {
    width: 100%;
}

/* IMAGEN LOGO */
.el-col:nth-of-type(2) .el-image {
    width: 40%;
}

/* Crea Mailings | Comunica | Fideliza | Crece */
.el-col:nth-of-type(1) .el-scrollbar__view > div > div {
    display: flex;
}

@media (max-width: 870px) {
    .el-col:nth-of-type(1) .el-scrollbar__view > div > div {
        flex-direction: column;
        gap: 10px;
    }
    .el-col:nth-of-type(1) .el-scrollbar__view > div > div > .el-divider {
        display: none;
    }
}

.el-col:nth-of-type(1) .el-scrollbar__view > div > div * {
    color: #fff;
    font-size: 15px;
}

.el-divider--vertical {
    height: 20px;
    margin: 0 15px;
}

@media (max-width: 600px) {
    .el-col:nth-of-type(1) {
        display: none;
    }

    .el-col:nth-of-type(2) {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .el-col:nth-of-type(1) .el-scrollbar__view > div,
    .el-col:nth-of-type(2) .el-scrollbar__view > div {
        padding: 0 10%;
    }
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
